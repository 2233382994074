<template>
  <v-container>
    <template>
      <v-data-table :headers="headers" :items="exam_metas" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px"></v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                >Bạn có chắc chắn muốn xóa không?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel
                  </v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
                  >OK
                  </v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- schedule  -->
            <v-dialog v-model="dialogSchedule" max-width="1000px">
              <v-card>
                <v-container>
                  <v-row>
                    <v-col cols="5"></v-col>
                    <v-col cols="5">
                      <span class="title schel">ĐẶT LỊCH THI</span>
                    </v-col>
                    <v-col cols="2"></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="2"></v-col>
                        <v-col cols="4">
                          <ValidationProvider
                              name="picker"
                              rules="required"
                              v-slot="{ errors }"
                          >
                            <v-date-picker v-model="picker"></v-date-picker>
                            <span
                                class="validate-error text-danger "
                            >
                              {{ errors[0] }}
                            </span>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="4">
                          <v-time-picker
                              v-model="timer"
                              format="ampm"
                          ></v-time-picker>
                        </v-col>
                        <v-col cols="2"></v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12">
                      <div class="validate-error text-danger text-center">{{ message }}</div>
                    </v-col>

                    <v-col cols="8"></v-col>
                    <v-col cols="4">
                      <v-btn large @click="close" class="btn-cancel">
                        Cancel
                      </v-btn>
                      <v-btn
                          large
                          @click="submitSchedule"
                          class="btn-submit"
                          style="background-color: black"
                      >
                        Save
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.title="{ item }">
          <div class="inline-block-style mr-2 " v-if="!item.is_edited" v-html="item.title"></div>
          <div class="border inline-block-style normal-text mr-2" v-else-if="item.is_edited">
            <input @keypress.enter="saveTitle" class="edit-input" v-model="item.title" type="text"/>
          </div>
          <BaseIcon class="mr-2" v-if="item.is_edited" @click="saveTitle(item)" size="20px" :src="iconCheck.src"/>
          <BaseIcon class="mr-2" v-if="item.is_edited" @click="cancelTitle(item)" size="20px" :src="iconCancel.src"/>
          <v-icon v-if="!item.is_edited" @click="toggleEdit(item)">mdi-lead-pencil</v-icon>
        </template>
        <template v-slot:item.Schedule="{ item }">
          <v-btn
              elevation="3"
              medium
              outlined
              raised
              @click="bookSchedule(item)"
              class="btn-submit"
              style="background-color: black"
          >Đặt lịch
          </v-btn>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon class="pa-1" small @click="viewExamMeta(item)">
            mdi-eye
          </v-icon>
          <v-icon class="pa-1" small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <!-- <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template> -->
      </v-data-table>
    </template>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {
  GET_EXAM_META_LIST,
  SAVE_EXAM_SCHEDULE,
  DELETE_EXAM_META,
  UPDATE_EXAM_META
} from "@/store/exams.module";
import {SUCCESS} from "@/store/alerts.module";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import BaseIcon from "@/components/base/BaseIcon";

export default {
  name: "ExamListSchel",
  data: () => ({
    iconCheck: {src: require('@/assets/icons/check_icon.png')},
    iconCancel: {src: require('@/assets/icons/muliply_icon.png')},
    picker: new Date().toISOString().substr(0, 10),
    timer: "11:15",
    //_________________________________________________________________________________________
    dialog: false,
    dialogDelete: false,
    dialogSchedule: false,
    headers: [
      {
        text: "Tên bài thi",
        align: "start",
        value: "title",
      },
      {text: "Thời gian làm bài(phút)", value: "exam_duration"},
      {text: "Trình độ ", value: "textLevel"},
      {text: "Đặt lịch thi", value: "Schedule"},
      {text: "Thao tác ", value: "actions"},
    ],
    exam_schedule: {},
    editedIndex: -1,
    editedItem: {},
    message: "",
    isExamWritting: null,
  }),
  components: {
    BaseIcon
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    ...mapGetters({
      exam_metas: "examMetaList",
    }),
  },
  async created() {
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Danh sách đề thi",titledad:'Đề thi' }]);
    await this.$store.dispatch(GET_EXAM_META_LIST, {});
  },

  watch: {
    exam_metas: {
      immediate: true,
      deep: true,
      handler(self) {
        self.forEach((element) => {
          if (element.level === 1) {
            element.textLevel = "Topik I";
          }
          if (element.level === 2) {
            element.textLevel = "Topik II";
          }
        });
      },
    },
  },

  methods: {
    saveTitle(item) {
      this.$store
          .dispatch(UPDATE_EXAM_META, {
            exam_meta: item,
          })
          .then((data) => {
            if (data.status) {
              this.$store.dispatch(SUCCESS, {
                type: "success",
                message: "Cập nhật tiêu đề thành công"
              }, {
                root: true,
              });
              this.toggleEdit(item)
            }
          });
    },
    toggleEdit(item) {
      item.is_edited = !item.is_edited
    },
    cancelTitle(item) {
      this.toggleEdit(item)
    },
    submitSchedule() {
      // format day with time
      this.exam_schedule.start_date_time = this.picker + " " + this.timer;
      if (this.isPastSchedule(this.exam_schedule.start_date_time)) {
        this.message = "Bạn không thể đặt thời gian đã qua"
        return null;
      } else {
        this.$store
            .dispatch(SAVE_EXAM_SCHEDULE, {
              exam_schedule: this.exam_schedule,
              isExamWritting: this.isExamWritting,
            })
            .then((data) => {
              if (data.data.status) {
                this.$router.push({
                  path: "/exam/schedule/list",
                });
              }
            });
      }
    },
    viewExamMeta(exam_meta) {
      this.$store.commit('activeMenuConfig')
      let routeData = this.$router.resolve({
        path: "/exam/select-type",
        query: {
          exam_meta_id: exam_meta.id,
        },
      });
      window.open(routeData.href, '_self');
    },

    editItem() {
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.exam_metas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store
          .dispatch(DELETE_EXAM_META, {
            exam_meta_id: this.editedItem.id,
          })
          .then((data) => {
            if (data.data.status) {
              this.exam_metas.splice(this.editedIndex, 1);
            }
          });
      this.closeDelete();
    },
    bookSchedule(item) {
      this.exam_schedule.exam_meta_slug = item.slug;
      if (item.level == 1) {
        this.isExamWritting = 0
      } else {
        this.isExamWritting = 1
      }
      this.dialogSchedule = true;
    },
    close() {
      this.dialogSchedule = false;
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    closeSchedule() {
      this.dialogSchedule = false;
    },
    save() {
      this.close();
    },

    // rule validate
    isPastSchedule(time) {
      let timeNow = new Date();
      return Date.parse(time) < timeNow
    }
  },
};
</script>

<style lang="scss">
.edit-input {
  padding-left: 5px;
  font-weight: normal;
}

.normal-text {
  font-weight: normal;
}

.border {
  border: 1px solid black;
}

.inline-block-style {
  display: inline-block;
}

.btn-submit {
  background-color: black;
  color: white !important;
  border-radius: 10px;
}

.btn-cancel {
  border-radius: 10px;
}

.title {
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 29px;
  display: flex;
}

.schel {
  font-size: 40px;
}
</style>
